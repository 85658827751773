<template>
    <AppContainer>
        <campaigns-list/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import CampaignsList from '@/components/dashboard/emails/CampaignsList.vue'

export default {
    name: 'emailCampaignsList',
    // beforeCreate: function () {
    //     var auth = this.$storage.get('auth')
    //     if (!auth) {
    //         this.$router.push('/login')
    //     }else{
    //         if(!auth.token){
    //             this.$router.push('/login')
    //         }
    //     }
    // },
    components: {
        AppContainer,
        CampaignsList,
    }
}
</script>